<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>

		<div v-if="addOrEditReady">
			<main id="liste" v-cloak>
				<HeaderTab :title="$t('monte.personnalisation_contrat_titre', {horse_nom: horse.horse_nom})" :swiper_tabs="[]" :back_action="back_action" />

				<div id="content">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<div>
								    <form @submit.prevent="checkForm">
										<div class="row box">
											<div class="col-12" v-if="form_message !== ''">
												<ErrorAlert :messageI18n="form_message" />
											</div>

											<div class="col-12">
											    <div class="form-group" v-if="contract_config.season && contract_config.season.number_template && contract_config.season.number_template.length == 0">
											        <label class="col-form-label">{{ $t("monte.numero_contrat") }}</label>
											        <div class="form-inline">
											            <input
											                class="form-control col-2"
											                v-model="form.contractconfig_prefix.numbertemplate_prefix"
											                required
											            	:readonly="readonly"

											            />
											            <b-input-group class="col-5">
											                <b-input-group-prepend id="popover-target-format-info">
											                    <span class="input-group-text">
											                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
											                    </span>
											                </b-input-group-prepend>
											                <b-form-input
											                    v-model="form.contractconfig_prefix.numbertemplate_pattern"
											                    required
											            		:readonly="readonly"

											                ></b-form-input>
											                <b-popover target="popover-target-format-info" triggers="hover" placement="top">
											                    <template v-slot:title>{{ $t('monte.format_numero_contrat') }}</template>
											                    <p
											                        v-for="(val, key) in date_formatter"
											                        :key="key"
											                    >
											                        <b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
											                    </p>
											                    
											                </b-popover>
											            </b-input-group>
											            <span disabled class="form-control col-5">
											                {{ formatted_contract_num }}
											            </span>
											        </div>
											    </div>
											    <div class="form-group" v-if="contract_config.season && contract_config.season.number_template && contract_config.season.number_template.length == 0">
											        <label class="col-form-label">{{ $t("monte.a_partir_de") }}</label>
											        <input
											            type="number"
											            class="form-control"
											            v-model="form.contractconfig_fromnumber"
											            required
											            :readonly="readonly"
											        >
											    </div>

											     <div class="form-group">
											        <label class="col-form-label">{{ $t("monte.nombre_paillettes") }}</label>
											        <input
											            type="number"
											            class="form-control"
											            v-model="form.contractconfig_paillette"
											            :readonly="readonly"
											        >
											    </div>

											    <div class="form-group">
											    	<label for="contractconfigtypemonte_id">{{ $t('gynecologie.saison') }} *</label>
											    	<e-select
											    		v-model="form.contractconfig_season"
											    		track-by="name"
											    		label="name"
											    		:placeholder="labelTitleSeason"
											    		:selectedLabel="selectedLabelSeason"
											    		:options="season_options"
											    		:searchable="true"
											    		:allow-empty="false"
											    		:show-labels="false"
														:disabled="true"
											    		:class="{ 'is-invalid': errors && errors.indexOf('season') > -1 }"
											    	>
											    		<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
											    		<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											    	</e-select>
											    </div>

											    <div class="form-group">
											    	<label for="contractconfig_configuration">{{ $t('monte.rib') }}</label>
											    	<e-select
											    		v-model="form.contractconfig_configuration"
											    		track-by="checkdepositconfiguration_id"
											    		label="checkdepositconfiguration_iban"
											    		:placeholder="labelTitleRib"
											    		:selectedLabel="selectedLabelRib"
											    		:options="rib_options"
											    		:searchable="true"
											    		:allow-empty="true"
											    		:show-labels="false"
											    	>
											    		<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
											    		<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
											    		<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											    	</e-select>
											    </div>

											    <div class="form-group">
											    	<label for="contractconfig_default_cmep">{{ $t('monte.cmep') }}</label>
											    	<SearchTiers 
														:tiers_selected.sync="form.cmep"
														:preselected="form.cmep"
													/>
											    </div>

											    <div class="form-group text-center">
											    	<button class="btn btn-primary rounded-pill" @click='saveInfo'>Enregistrer <font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" /></button>
											    </div>
											</div>
										</div>

										<div class="row box">
											<div class="col-12">
												<h3 class="mb-4">{{ $t('monte.conditions') }}</h3>
												<CustomTable
													id_table="contract_config_type_monte"
													ref="table"
													:items="filtredContractConfigTypeMonte"
													:busy="table_busy"
													primaryKey="contractconfigtypemonte_id"
													:hide_if_empty="true"
													:columsAdd="typeMonteAddColumns"
													:rawColumns="columsAdd"
													:externSlotColumns="['available_ca']"
												>
													<template v-slot:[`custom-slot-cell(available_ca)`]="{ data }">
														<b-form-checkbox
															class="custom-control custom-checkbox float-right mr-2"
															v-model="data.contractconfigtypemonte_available_ca"
															@input="switchAvailableCa(data)"
															name="check-default-ca" 
															switch
														>
														</b-form-checkbox>
													</template>
												</CustomTable>
											</div>
										</div>

										<div class="row box"> <!-- v-if="has_model"> -->
											<div class="col-12">
												<h3 class="mb-4">{{ $t('monte.modeles_contrats') }}</h3>

												<CustomTable
													id_table="contract_config_modeles"
													ref="table_models"
													:items="models"
													:busy="table_busy_models"
													primaryKey="model_id"
													:columsAdd="modelsAddColumns"
													:externSlotColumns="['default_ca']"
												>
													<template v-slot:[`custom-slot-cell(default_ca)`]="{ data }">
														<b-form-checkbox
															class="custom-control custom-checkbox float-right mr-2"
															v-model="data.default_ca"
															@change="switchDefaultModelCa(data)"
															name="check-default-ca" 
															switch
														>
														</b-form-checkbox>
													</template>
												</CustomTable>
												<!-- <EditModel :modelable_id="contractconfig_id" :modelable_type="'contract_config'" :model_id="models.model_id" /> -->
											</div>
										</div>
										<!-- <div class="row box" v-else>
											<div class="col-12">
												<div class="alert alert-info">{{ $t('monte.no_model_for_contract') }}</div>
											</div>
										</div> -->
									</form>
								</div>


								<b-modal size="xl" ref="modalAddContractConfigCondition" hide-footer>
									<template v-slot:modal-title>
										{{ $t("monte.ajouter_une_condition") }}
									</template>

									<ConditionForm ref="ConditionForm" :contractconfig_id="contractconfig_id" :processing.sync="processing" :current_step.sync="current_step" :nb_step="nb_step" :type_monte="type_monte" :conditions="conditions_monte" :contract_type_monte="current_contract_type_monte"></ConditionForm>

									<div class="col-8 m-auto">
										<b-button v-if="current_step < nb_step" block pill variant="primary" @click.prevent="nextStep"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.suivant") }}</b-button>
										<b-button v-else block pill variant="primary" @click.prevent="onSend"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
									</div>
								</b-modal>

							</div>
						</div>
					</div>
				</div>
			</main>
		</div>

		<ModalSelectCondition ref="modal_select_condition" @submit="stopSpin"/>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Accounting from "@/mixins/Accounting.js"
	import ContractMixin from "@/mixins/Contract.js"
	import GynecologieMixin from "@/mixins/Gynecologie.js"
	import HorseMixin from "@/mixins/Horse.js"
	import ModelMixin from "@/mixins/Model.js"
	import SyncWatcher from "@/mixins/SyncWatcher.js"
	import _sortBy from "lodash/sortBy"
	import { EventBus } from 'EventBus'

	export default {
		name: "ContractConfigForm",
		props: ["horse_id"],
		mixins: [Navigation, Tools, ContractMixin, HorseMixin, ModelMixin, SyncWatcher, GynecologieMixin, Accounting],
		data () {
			return {
				form_message: "",
				horse: {},
				columsAdd: [],
				conditions_monte: [],
				season_options: [],
				contractConfigTypeMonte: [],
				errors: [],
				labelTitleSeason: this.getTrad("monte.rechercher_saison"),
				selectedLabelSeason: this.getTrad("global.selected_label"),
				labelTitleRib: this.getTrad("monte.rechercher_rib"),
				selectedLabelRib: this.getTrad("monte.rechercher_rib"),
				rib_options: [],
				current_contract_type_monte: {},
				type_monte: [],
				events_tab: {
					'TableAction::goToAddContractConfigTypeMonte': () => {
						this.current_contract_type_monte = {}
						this.current_step = 1
						this.$refs['modalAddContractConfigCondition'].show()
					},
					'TableAction::goToEditContractConfigTypeMonte': (params) => {
						this.current_contract_type_monte = this.contractConfigTypeMonte.filter(elem => elem.contractconfigtypemonte_id == params.contractconfigtypemonte_id)[0]
						this.current_step = 1
						this.$refs.table.resetCachedItems()
						this.$refs['modalAddContractConfigCondition'].show()
					},
					'TableAction::goToDeleteContractConfigTypeMonte': (params) => {
						this.deleteContractConfigTypeMonte(params.contractconfigtypemonte_id)
					},
					'TableAction::goToArchiveContractConfigTypeMonte': (params) => {
						this.archiveContractConfigTypeMonte(params.contractconfigtypemonte_id, params.contractconfigtypemonte_archive)
					},
					'TableAction::goToAddContractConfigModel': this.addContratConfigModel,
					'TableAction::goToDeleteModeleContractConfig':  (params) => {
						this.deleteContractConfigModele(params)
					},
					'TableAction::goToEditContractConfigModel': (lines) => {
						this.goToEditModelContract(lines[0].model_id)
					},
					'TableAction::goToPrintBlankContract': (lines) => {
						this.openModalSelectCondition(lines[0].model_id)
					}
				},
				form: {
					contractconfig_season: {},
					contractconfig_fromnumber: 0,
					contractconfig_configuration: null,
					cmep: {},
					contractconfig_prefix: {
						numbertemplate_prefix: '',
						numbertemplate_pattern: '9999'
					},
					contractconfig_paillette: 0
				},
				contract_save: {},
				date_formatter: {
                    '9': {
                        label: this.$t('compta.form.format_9'),
                        value: 0
                    },
                },
				current_step: 1,
				nb_step: 2,
				loading: false,
				processing: false,
				saving: false,
				table_busy: true,
				addOrEditReady: false,
				readonly: false,
				has_model: false,
				saveButton: false,
				reloadFnName: 'loadModeleContractConfig',
				hasAlreadyReloadModele: false,
				models: [],
				table_busy_models: false,
				contract_config: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loading = true
				this.form_message = ""
				this.$emit('update:addOrEditReady', false)

				//Je vais chercher les infos principale de mon contrat
				this.contractconfig_id = this.$route.params.contractconfig_id
				this.contract_config = await this.getContractsConfig(this.contractconfig_id)

				this.horse = this.contract_config.horse

				let seasons = await this.loadSeasons()
				for (let i = 0; i < seasons.length; i++) {
					this.season_options.push({
						id: seasons[i].season_id,
						name: seasons[i].season_label,
					})

					if(seasons[i].season_id == this.contract_config.season.season_id) {
						this.form.contractconfig_season = {
							id: seasons[i].season_id,
							name: seasons[i].season_label,
						}
					}
				}

				if(!window.navigator.onLine) {
					this.readonly = true
				}
				else {
					let retour = await this.getContractPrefix(this.contractconfig_id)
					if(retour != undefined) {
						this.form.contractconfig_prefix.numbertemplate_prefix = retour.numbertemplate_prefix
						this.form.contractconfig_prefix.numbertemplate_pattern = retour.numbertemplate_pattern
					}
					else {
						this.form.contractconfig_prefix.numbertemplate_prefix = this.accentsTidy(this.horse.horse_nom.replace(' ', '').substr(0, 5)).toUpperCase()
						//Je vais créer automatiquement un number template
						this.saveInfo()
					}
				}

				let all_ribs = await this.getAllCheckDepositConfig()
				this.rib_options = []
				for (let index = 0; index < all_ribs.length; index++) {
					const element = all_ribs[index];

					this.rib_options.push(element)
					if(this.contract_config.contractconfig_configuration && (this.contract_config.contractconfig_configuration == element.checkdepositconfiguration_id || (this.form.contractconfig_configuration != null && this.form.contractconfig_configuration.checkdepositconfiguration_id == element.checkdepositconfiguration_id))){
						this.form.contractconfig_configuration = element
					}
				}

				

				//Je vais récup les conditions + type de monte
				this.conditions_monte = await this.getConditionsMonte()
				//J'ajoute les colonnes dans mon tableau pour les différents frais
				for (let i = 0; i < this.conditions_monte.length; i++) {
					this.columsAdd.push(this.conditions_monte[i].contractconditions_type)
				}

				this.type_monte = await this.getTypeMonte()

				
				
				let contractConfigTypeMonte = await this.getContractConfigTypeMonte(this.contractconfig_id)

				for (let i = 0; i < contractConfigTypeMonte.length; i++) {
					//Je vais chercher les articles en fonction de mon type de monte
					for (let j = 0; j < this.conditions_monte.length; j++) {
						let conditions = await this.getContractConfigCondition(this.contractconfig_id, this.conditions_monte[j].contractconditions_id, contractConfigTypeMonte[i].contractconfigtypemonte_id)

						//Si j'ai des articles sur ma conditions, je parcours et j'ajoute dans mon tableau
						if(conditions.length > 0) {
							for (let k = 0; k < conditions[0].conditions_articles.length; k++) {
								if(contractConfigTypeMonte[i][this.conditions_monte[j].contractconditions_type] == undefined) {
									contractConfigTypeMonte[i][this.conditions_monte[j].contractconditions_type] = []
								}
								contractConfigTypeMonte[i][this.conditions_monte[j].contractconditions_type].push(conditions[0].conditions_articles[k])
							}
						}
					}

					contractConfigTypeMonte[i].pays_tab = contractConfigTypeMonte[i].pays.map(c => c.typemontepays_pays)
				}

				this.contractConfigTypeMonte = contractConfigTypeMonte
				this.form.contractconfig_fromnumber = this.contract_config.contractconfig_fromnumber
				this.form.contractconfig_paillette = this.contract_config.contractconfig_paillette

				if(Array.isArray(this.contract_config.cmep) === false)
				{
					this.form.cmep = this.contract_config.cmep
				}

				this.contract_save = this.deepClone(this.form)

				await this.loadModeleContractConfig()

				this.table_busy = false
				this.addOrEditReady = true
				this.$emit('update:addOrEditReady', true)
				this.loading = false
			},
			async loadModeleContractConfig(){
				if(!this.hasAlreadyReloadModele) {
					//Je vais checker les différents modeles de courriers
					//Je récupère l'id du contrat après la synchro
					let contract_config = await this.getContractsConfig(this.contractconfig_id)
					this.models = await this.loadAllModel(contract_config.contractconfig_id, ['contract_config'])

					if(this.models.length > 0) {
						this.has_model = true
						this.hasAlreadyReloadModele = true
					}
				}
			},
			nextStep() {
				this.current_step ++
			},
			async onSend() {
			    let return_send = await this.$refs['ConditionForm'].checkForm()

			    if(return_send)  {
			        this.$refs["modalAddContractConfigCondition"].hide()
			        this.table_busy = true
			        this.contractConfigTypeMonte = []
			        await this.init_component()
			    }
			},
			async deleteContractConfigTypeMonte(contractconfigtypemonte_id) {
				try {
					await this.deleteTypeMonte(contractconfigtypemonte_id)

					this.$refs.table.resetCachedItems()
					this.table_busy = true
	                await this.init_component()
				}
				catch {
					this.failureToast()
				}
				
			},
			async archiveContractConfigTypeMonte(contractconfigtypemonte_id, actual_state) {
				try {
					await this.archiveTypeMonte(contractconfigtypemonte_id, !actual_state)

					this.$refs.table.resetCachedItems()
					this.table_busy = true
	                await this.init_component()
				}
				catch {
					this.failureToast()
				}
			},
			back_action(){
				this.$router.push({name: "ContractConfigStallionList", params: {horse_id: this.horse.horse_id}})
			},
			showSaveButton() {
				this.saveButton = true
			},
			hideSaveButton() {
				this.saveButton = false
			},
			async saveInfo(){
				this.saving = true
				const save = await this.updateContractConfig(this.contractconfig_id, this.form)
                if (save){
                    this.successToast("toast.info_modif_succes")
                }
				this.contract_save = this.deepClone(this.form)
				this.saving = false
				this.hideSaveButton()
			},
			cancelInfo(){
				this.form = this.contract_save
			},
			async addContratConfigModel() {
				let contractconfig_id = this.contractconfig_id
				if(contractconfig_id < 0) {
					await this.$sync.force(true)
					contractconfig_id = this.$sync.replaceWithReplicated('contract_config', this.contractconfig_id)
				}
				const res = await this.addContractConfigModel(contractconfig_id, {
					model_label: this.horse.horse_nom
				})

				if(!res) {
					this.failureToast()
					return
				}

				this.goToEditModelContract(res.model_id)
			},
			goToEditModelContract(model_id) {
				this.$router.push({ 
					name: 'configModel', 
					params: { 
						modelable_id: this.contractconfig_id,
						modelable_type: 'contract_config',
						model_id: model_id
					}
				})
			},
			switchDefaultModelCa(model) {
				const to_switch = this.models.find(m => m.model_id == model.model_id)
	
				// Si l'utilisateur veut désactiver le modèle
				if(to_switch.default_ca === true) {
					this.failureToast('toast.contract_ca_default_model')
					this.$refs.table_models.refreshTable()
					return
				}

				to_switch.default_ca = !to_switch.default_ca
				this.setDefaultCaModelContract(model.model_id, this.contractconfig_id)

				this.models.filter(m => m.model_id != model.model_id)
					.forEach(m => m.default_ca = false)

				this.$refs.table_models.refreshTable()
			},
			switchAvailableCa(model) {
				this.updateContractConfigConditionAvailableCa(model.contractconfigtypemonte_id, model.contractconfigtypemonte_available_ca)
			},
			async deleteContractConfigModele(params){
				const models = await this.deleteModel([params.model_id]);
				if(models.length > 0)
				{
					this.hasAlreadyReloadModele = false
					this.successToast()
					this.loadModeleContractConfig()
					this.$refs.table_models.unselectAll()
				}
				else{
					this.failureToast()
				}
			},
			openModalSelectCondition(model_id) {
				this.$refs.modal_select_condition.openModal(this.contractconfig_id, model_id, this.filtredContractConfigTypeMonte)
			},
			stopSpin() {
				EventBus.$emit("TableAction::stopSpin", "goToPrintBlankContract")
			}
		},
		computed: {
			hasDedicatedCa() {
                return this.$store.state.userAccess.hasDedicatedClientArea
			},
			filtredContractConfigTypeMonte: function() {
				let tab = []

				for (let i = 0; i < this.contractConfigTypeMonte.length; i++) {
					let temp = this.deepClone(this.contractConfigTypeMonte[i])
					temp.contract_type_monte.contracttypemonte_label = this.getTrad(temp.contract_type_monte.contracttypemonte_label)

					for (let j = 0; j < this.conditions_monte.length; j++) {
						let label = this.conditions_monte[j].contractconditions_type

						if(temp[label] == undefined) {
							temp[label] = ""
						}
						else {
							const tab_temp = temp[label]
							temp[label] = []
							for (let k = 0; k < tab_temp.length; k++) {
								temp[label].push(tab_temp[k].articles.articles_label)
							}

							temp[label] = temp[label].join("<br>")
						}
					}
					temp.country = temp.pays.length > 0

					tab.push(temp)
				}

				return _sortBy(tab, ['contractconfigtypemonte_label'])
			},
			formatted_contract_num () {
			    let num = this.form.contractconfig_prefix.numbertemplate_pattern

			    Object.keys(this.date_formatter).forEach(identifier => {
			        num = num.replace(new RegExp(identifier, 'g'), this.date_formatter[identifier].value)
			    })

			    return this.form.contractconfig_prefix.numbertemplate_prefix + num
			},
			typeMonteAddColumns() {
				if(!this.hasDedicatedCa) return this.columsAdd
				return [...this.columsAdd, 'available_ca']
			},
			modelsAddColumns() {
				if(!this.hasDedicatedCa) return []
				return ['default_ca']
			}
		},
		watch: {
			form: {
				handler(val){
					val.contractconfig_fromnumber = parseInt(val.contractconfig_fromnumber)

					if(JSON.stringify(val) != JSON.stringify(this.contract_save)) {
						this.showSaveButton()
					}
					else {
						this.hideSaveButton()
					}
				},
				deep: true
			}
		},
		components: {
			ConditionForm : () => import('@/components/Contract/ConditionForm'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			EditModel : () => import('@/components/Model/Edit'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ModalSelectCondition : () => import('@/components/Modals/ModalSelectCondition'),
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}
</script>
